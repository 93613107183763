<template>
  <a-modal
    title="编辑分类"
    width='50%'
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 分类名称 -->
            <a-form-item label="分类名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input 
                placeholder="请输入"
                v-decorator="['name', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
            <!-- 城市 -->
            <a-form-item label="城市" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-row type="flex" justify="center" align="middle">
                <a-col :span="24">
                    <!-- 省选择框 -->
                    <a-select 
                      placeholder="请选择"
                      style="width: 160px" 
                      @focus="focusProv"
                      @change="changeProv"
                      v-model="provinceName"
                    >
                      <a-select-option :value="item.districtName" v-for="(item, index) in provinceList" :key="index">
                        {{ item.districtName }}
                      </a-select-option>
                    </a-select>
                    <!-- 市选择框 -->
                    <a-select 
                      placeholder="请选择"
                      style="width: 160px" 
                      v-decorator="['city', { rules: [{ required: true, message: '请选择！' }] }]"
                    >
                      <a-select-option :value="item.districtName" v-for="(item, index) in cityList" :key="index">
                        {{ item.districtName }}
                      </a-select-option>
                    </a-select>
                </a-col>
              </a-row>
            </a-form-item>
            <!-- 排序 -->
            <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number 
                placeholder="请输入"
                :min="1"
                :max="1000"
                style="width: 40%;min-width: 100px"
                v-decorator="['sort', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
            <a-form-item label="展示图" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <div class="flex">
								<a-upload 
                    list-type="picture-card" 
                    :file-list="fileList" 
                    @preview="handlePreview"
										accept=".jpg,.png,.jpeg,.gif" 
                    @change="handleChange"
										:customRequest="showFigureRequest"
                  >
										<div v-if="fileList.length < 10">
											<a-icon type="plus" />
											<div style="color: #999" class="ant-upload-text">最多上传10张</div>
										</div>
									</a-upload>
									<a-modal :visible="previewVisible1" :footer="null" @cancel="handleCancel1">
										<img alt="example" style="width: 100%" :src="previewImage" />
									</a-modal>
							 </div>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { liveClassEdit, liveClassDetail } from '@/api/modular/mallLiving/liveClass'

import { getUploadScreat } from '@/api/modular/mallLiving/uploadScreat'
import COS from 'cos-js-sdk-v5'

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      provinceName: undefined, //省份名称
      provinceList:[], //省份列表
      cityList:[], //市区列表
      //轮播图相关
      fileList:[],
      cos: null,
      previewVisible1: false,
      previewImage: '',
    }
  },

  methods: {
    // 初始化方法
    edit(record) {
      let that = this;
			let msgData = sessionStorage.getItem("upload-screat-msg")
			if (msgData) {
				msgData = JSON.parse(sessionStorage.getItem("upload-screat-msg"))
				var etime = new Date().getTime()
				if (msgData.expiredTime * 1000 < etime + 20 * 60 * 1000) {
					this.getUploadScreat()
				} else {
					let data = {
						TmpSecretId: msgData.tmpSecretId,
						TmpSecretKey: msgData.tmpSecretKey,
						XCosSecurityToken: msgData.sessionToken,
						StartTime: msgData.startTime,
						ExpiredTime: msgData.expiredTime
					}
					that.cos = new COS({
						getAuthorization: function(options, callback) {
							callback(data)
						}
					})
				}
			} else {
				this.getUploadScreat()
			}

      //下面是正常的初始化方法
      this.visible = true
      this.confirmLoading = true
      this.id = record.id
      setTimeout(()=>{
        //调取详情接口获取回显数据
        liveClassDetail({id: this.id}).then((res)=>{
          if(res.success){
              var details = res.data
              this.confirmLoading = false
              // 遍历images拼出fileList数组的格式
              if(details.images){
                console.log('images',details.images)
                this.fileList = details.images.split(',').map((item, index)=>{
                  return { 
                    url: item,
                    uid: index+1,
                    name: 'image'+index,
                    status: 'done',
                  }
                })
              }
              // console.log(this.fileList)
              //回显数据
              setTimeout(()=>{
                this.form.setFieldsValue({
                  name: details.name,
                  city: details.city,
                  sort: details.sort,
                  images: details.images,
                })
                this.provinceName = details.province  //回显省份
                // console.log('省份',this.provinceName)
                this.findCity() //根据省名称查询市
              }, 200)
          }else{
            setTimeout(()=>{
              this.confirmLoading = false
            },2000)
          }
        })
      }, 200)
    },
    //查询省份信息
    findProv(){
      this.axios.post('/sysDistrict/allProvince').then((res)=>{
        if(res.success){
          this.provinceList = res.data
        }
      })
    },
    focusProv(){
      this.findProv()
    },
    //调取通过省份查询城市接口
    findCity(){
      this.axios.post('/sysDistrict/findCityByProvinceName', null, {params:{province:this.provinceName}}).then((res)=>{
        if(res.success){
          this.cityList = res.data
        }
      })
    },
    //切换省份发送请求市信息
    changeProv(){
      this.form.setFieldsValue({city:undefined}) //每次切换省份之后把城市置为空
      this.findCity()
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        values.province = this.provinceName
        if (!errors) {
            var files = ''
            this.fileList.forEach((item, index) => {
              files += (index < this.fileList.length - 1 ) ? (item.url+',') : item.url
            })
            values.images = files
            
            liveClassEdit({id:this.id, ...values}).then((res) => {
              if(res.success){
                this.$message.success("编辑成功！")
                this.$emit("ok",values)
                this.confirmLoading = false
                this.handleCancel()
              }else{
                setTimeout(()=>{
                  this.confirmLoading = false
                }, 600)
              }
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.provinceName = undefined //清空省份名称
      this.provinceList = []//清空省份列表
      this.cityList = [] //清空城市列表
      this.fileList = []  //清空图片列表fileList
      this.form.resetFields() //重置表单.
    },
    formatFileName(){
				var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
				a = t.length,
				n = "";
				
				for (var i = 0; i < 10; i++) n += t.charAt(Math.floor(Math.random() * a));
				return n + new Date().getTime()
			},
    // 自定义上传实现
    showFigureRequest(filedata) {
      let that = this;
      this.cos.putObject({
          // Bucket: 'chanbow-1309518477' /* 必须 */ ,
			 Bucket: "cos-1311593864",
          Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */ ,
          Key: that.formatFileName() + '.' + filedata.file.name.split(".")[filedata.file.name.split(".").length-1]/* 必须 */ ,
          StorageClass: 'STANDARD',
          Body: filedata.file, // 上传文件对象
          onProgress: (progressData) => {},
        },
        (err, data) => {
          // 将上传后的封面进行路径拼接保存到本地
          if (data.Location) {
            that.fileList = this.fileList.slice(0, that.fileList.length - 1)
            this.fileList.push({
              url: 'https://' + data.Location,
              uid: this.fileList.length + 1,
              name: filedata.file.name,
              status: 'done',
            })
          }
        }
      )
    },
    // 图片上传，cos
    getUploadScreat() {
				let that = this
				getUploadScreat().then((res) => {
					that.uploadOption = res.data
					let data = {
						TmpSecretId: res.data.tmpSecretId,
						TmpSecretKey: res.data.tmpSecretKey,
						XCosSecurityToken: res.data.sessionToken,
						StartTime: res.data.startTime,
						ExpiredTime: res.data.expiredTime
					}
					sessionStorage.setItem("upload-screat-msg", JSON.stringify(res.data))
					that.cos = new COS({
						getAuthorization: function(options, callback) {
							callback(data)
						}
					})
				})
		},
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible1 = true
    },
    handleChange({ fileList }) {
      this.fileList = fileList
    },
    handleCancel1() {
      this.previewVisible1 = false
    },
    Cancelpreview2(){
      this.previewVisible = false
    },
  },
  
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>